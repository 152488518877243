import { defineStore } from "pinia";

export const useAnimationsStore = defineStore("animations", {
  state: () => ({
    animationsEnabled: localStorage.getItem("animationsEnabled")
      ? localStorage.getItem("animationsEnabled") === "true"
      : true,
  }),

  actions: {
    setAnimationsEnabled(value) {
      this.animationsEnabled = value;
      localStorage.setItem("animationsEnabled", value);
    },
  },
});
